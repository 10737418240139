import Form from '../../../scripts/form'
import { pair } from '../../../scripts/date'
import { UTCString, convertTZ} from '../../../scripts/date'


class SlotSettingsFormInstance extends Form{
	id = -1
	title = ""
	duration = 60
	
	template = null

	dateFormat 
	calendarDate = null
	convertedDate = null
	date
	timeString = ""
	dateString = ""

	description = ""
	timezone = null
	timezoneId = null
	
	hostId = null 		// user id from backend
	hostIndex = -1	// user index in array of hosts for v-model

	ratingId = -1
	ratingIndex = -1

	priceLevelId = -1
	priceLevelIndex = -1

	cellBackgroundColor = ""
	cellTextColor = ""
	
	maxAttenders = 1
	isCreateTemplate = false
	isWaitingListEnabled = false
	isReadyToAttend = false
	chargingRule = 0

	calendarTimezone

	fixedAttenders = []
	wallpapers = []

	attenders = []
	isShort
	isCreate
	nonTemplateState = {}

	constructor (params, isShort=false,) {
		super(params)
		this.id = params.id ?? -1
		this.title = params.title ?? ''
		this.description = params.description ?? ''
		
		this.duration = params.duration ?? 60
		this.timezoneId = params.timezoneId ?? null
		
		this.date = params.startAt ? new Date(params.startAt) : new Date()
		this.timeString = `${pair(this.date.getHours())}:${pair(this.date.getMinutes())}`
		this.dateString = `${pair(this.date.getDate())}.${pair(this.date.getMonth())}.${pair(this.date.getFullYear())}`

		let isSlotBackground = params.cellBackgroundColor && params.cellBackgroundColor.length != 0
		this.cellBackgroundColor = isSlotBackground ? params.cellBackgroundColor : "#e6e6fa"

		let isSlotTextColor = params.cellTextColor && params.cellTextColor.length != 0
		this.cellTextColor = isSlotTextColor ? params.cellTextColor : "#000000"
		this.hostId = params.hostId ?? null
		if (params.regular && params.regular.chargingRule) {
			this.chargingRule = params.regular.chargingRule - 1
		}
		if (params.attenders) {
			this.attenders = params.attenders
		} 
		this.isShort = isShort
		this.isCreate = params.isCreate ?? false
		if (isShort) 
			return

		this.fixedAttenders = params.fixedAttenders ?? []
		this.wallpapers = params.wallpapers ?? []

		this.ratingId = params.ratingId ?? -1
		this.priceLevelId = params.levelId ?? -1

		this.maxAttenders = params.maxAttenders ?? 1
		this.isWaitingListEnabled = params.isWaitingListEnabled ?? false
		this.isReadyToAttend = params.isReadyToAttend ?? false
	}

	updateTime(timeString, browserTimezone){

		let date = new Date(this.date.getTime())
		let parts = timeString.split(":")
		let hours = Number(parts[0])
		let minutes = Number(parts[1])
		date.setHours(hours, minutes, 0)

		let now = new Date()
		now = convertTZ(now, browserTimezone, this.calendarTimezone)

		if (!this.isShort && date.getTime() <= now.getTime()){ 
			this.fields["time"].setError("time is invalid")
		}
		
		// Add check of the work time
		
		this.date = date
	}

	updateDate(dateString, browserTimezone, work){

		let date = new Date(this.date.getTime())
		let places = this.dateFormat.places
		let parts = dateString.split(this.dateFormat.sepr)
		let year = parts[places["YYYY"]]
		let month = Number(parts[places["MM"]]) - 1
		let day = parts[places["dd"]]
		date.setFullYear(year, month, day)
		if (this.isShort) {
			this.date = date
			return
		}

		let now = new Date()
		now = convertTZ(now, browserTimezone, this.calendarTimezone)
		let nowMinute = now.getMinutes()
		let nowHours = now.getHours()
		now.setHours(0,0,0,0)
		if (date.getTime() == now.getTime()) {
			if (nowHours < work.start.hour || 
			(nowHours == work.start.hour && nowMinute < work.start.minute))
				this.fields["date"].setError("date is invalid")
			else if (nowHours > work.end.hour || 
			(nowHours == work.end.hour && nowMinute > work.end.minute))
				this.fields["date"].setError("date is invalid")
			else
				this.data = date
		} else if (date.getTime() >= now.getTime()){ 
			this.date = date
		} else {
			this.fields["date"].setError("date is invalid")
		}
	}

	updateHostIndex(hosts){
		if (hosts == undefined || hosts.length == 0){
			return 
		}

		this.hostIndex = hosts.findIndex(host => host.id == this.hostId) ?? -1
	}

	updateHostId(hosts){
		if (hosts == undefined || hosts.length == 0){
			return 
		}

		let host = hosts[this.hostIndex]
		this.hostId = host? host.id : null
	}

	updateRatingIndex(ratings){
		if (ratings == undefined || ratings.length == 0){
			return 
		}

		this.ratingIndex = ratings.findIndex(rating => rating.id == this.ratingId) ?? -1
	}

	updateRatingId(ratings){
		if (ratings == undefined || ratings.length == 0){
			return 
		}

		let rating = ratings[this.ratingIndex]
		this.ratingId = rating? rating.id : -1
	}


	updatePriceLevelIndex(levels){
		if (levels == undefined || levels.length == 0){
			return 
		}

		this.priceLevelIndex = levels.findIndex(level => level.id == this.priceLevelId) ?? -1
	}

	updatePriceLevelId(levels){
		if (levels == undefined || levels.length == 0){
			return 
		}

		let level = levels[this.priceLevelIndex]
		this.priceLevelId = level? level.id : -1
	}

	validate(){
		
		// Date
		let dateIsClear = this.dateString.length == 0
		let dateIsError = this.fields["date"].isError
		let timeIsClear = this.timeString.length == 0
		let timeIsError = this.fields["time"].isError
		if (!this.isShort && (dateIsClear || dateIsError)) {
			this.fields["date"].setError("date is invalid")
			return false
		}
		if (!this.isShort && (timeIsClear || timeIsError)) {
			this.fields["time"].setError("time is invalid")
			return false
		}


		// Duration 
		let durationIsClear = typeof this.duration == 'string' && this.duration == ""
		let durationIsError = Number.isInteger(this.duration) && this.fields["duration"].isError
		if (durationIsClear || durationIsError) {
			this.fields["duration"].setError("duration is invalid")
			return false
		}

		return true
	}

	validateConvertedDate(browserTimezone){


		let date = this.calendarDate ?? this.date

		// Add extra hours to synchronize browser and calendar timezones
		this.convertedDate = convertTZ(date, this.calendarTimezone, browserTimezone)

		if (this.isShort) {
			return true
		}

		let now = new Date()
		let convertedNow = convertTZ(now, browserTimezone, this.calendarTimezone)

		// If past
		if (date.getTime() < convertedNow.getTime()) {
			this.fields['time'].setError("time is invalid")
			return false
		} 

		

		return true
	}

	toSubmitData(){

		// Mandatory part

		let submitData = {
			startAt: UTCString(this.convertedDate),
			dur: Number(this.duration),
			timezoneId: this.timezone.id ?? this.calendarTimezone.id,
			
			cellTextColor: this.cellTextColor,
			cellBackgroundColor: this.cellBackgroundColor,
		}
		
		if (this.title.length > 0)
			submitData.title = this.title
		if (this.description.length > 0)
			submitData.description = this.description

		if (this.isCreateTemplate)
			submitData.isTemplate = this.isCreateTemplate


		// Slot for the pro calendar		
		if (!this.isShort) {

			if (this.hostId != null)
				submitData.hostId = this.hostId

			if (this.attenders && this.attenders.length > 0)
				submitData.attenders = this.attenders

			submitData["pro"] = {}

			if (this.fixedAttenders)
				submitData["pro"].fixedAttenders = this.fixedAttenders	
			if (Number(this.maxAttenders) != 0 && Number(this.maxAttenders) != NaN)
				submitData["pro"].maxAttenders = Number(this.maxAttenders)
			if (this.isWaitingListEnabled)
				submitData["pro"].isWaitingListEnabled = this.isWaitingListEnabled
			if (this.priceLevelId != -1)
				submitData["pro"].levelId = this.priceLevelId
			if (this.ratingId != -1)
				submitData["pro"].ratingId = this.ratingId
		
		// Slot for the regular calendar
		} else {

			submitData["regular"] = {}

			if (this.chargingRule == 1 && this.attenders && this.attenders.length > 0) {
				submitData.attenders = this.attenders
				submitData.hostId = null
			}
			
			if (this.chargingRule == 2 && this.hostId != null) {
				submitData.attenders = null
				submitData.hostId = this.hostId
			}

			if (this.chargingRule != NaN)
				submitData["regular"].chargingRule = this.chargingRule + 1
		}

        return submitData
	}

	saveNonTemplateState(){
		this.nonTemplateState = {
			title 		: this.title,
			description : this.description,
			duration 	: this.duration,
			timezoneId	: this.timezoneId,
			cellBackgroundColor : this.cellBackgroundColor,
			cellTextColor 		: this.cellTextColor,
			fixedAttenders 		: this.fixedAttenders,
			wallpapers 			: this.wallpapers,
			hostId 				: this.hostId,
			levelId 			: this.priceLevelId,
			ratingId 			: this.ratingId,
			maxAttenders		: this.maxAttenders,
			isWaitingListEnabled : this.isWaitingListEnabled,
			isReadyToAttend 	: this.isReadyToAttend,
		}
	}

	updateByTemplate(newTemplate, oldTemplate){
		if (!newTemplate && !oldTemplate)
			return 
		if (newTemplate && !oldTemplate) {
			this.saveNonTemplateState()
		}
		if (!newTemplate && oldTemplate) {
			return this.updateByTemplate(this.nonTemplateState, oldTemplate)
		}
		let template = newTemplate
		this.title = template.title ?? this.title
		this.description = template.description ?? this.description
		
		this.duration = template.duration ?? this.duration
		this.timezoneId = template.timezoneId ?? this.timezoneId

		this.cellBackgroundColor = template.cellBackgroundColor ?? this.cellBackgroundColor
		this.cellTextColor = template.cellTextColor ?? this.cellTextColor

		this.fixedAttenders = template.fixedAttenders ?? this.fixedAttenders
		this.wallpapers = template.wallpapers ?? this.wallpapers

		this.hostId = template.hostId ?? this.hostId
		this.priceLevelId = template.levelId ?? this.priceLevelId
		this.ratingId = template.ratingId ?? this.ratingId

		this.maxAttenders = template.maxAttenders ?? this.maxAttenders
		this.isWaitingListEnabled = template.isWaitingListEnabled ?? this.isWaitingListEnabled
		this.isReadyToAttend = template.isReadyToAttend ?? this.isReadyToAttend
	}

	cache(){
		const slotId = this.id == -1 ?
			0 : this.id
		const formData = JSON.stringify(this)
		localStorage.setItem(`slot-edit-form-cache-${slotId}`, formData)
	}

	loadCache(slotId){
		const savedStateString = localStorage.getItem(`slot-edit-form-cache-${slotId}`)
		if (!savedStateString)
			return false
		const savedState = JSON.parse(savedStateString)
		if (!savedState)
			return false
		const currentInstanceFields = Object.keys(this)
		Object.keys(savedState).forEach(itemKey => {
			if (currentInstanceFields.includes(itemKey)) {
				this[itemKey] = savedState[itemKey]
			}
		})
		this.date = new Date(this.date)
		localStorage.removeItem(`slot-edit-form-cache-${slotId}`)
		return true
	}
}

export default SlotSettingsFormInstance